<template>
  <div class="frais">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="frais-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
          :title="$t('simulateurs.frais.title')"
          :has-back="true"
          @back="goBack()"
        />
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.frais.distance") }}</label>
                <md-input
                  v-model.number="$v.form.distance.$model"
                  name="distance"
                />
                <span class="md-suffix">{{ $t("suffix.kilometre") }}</span>

                <template v-if="$v.form.distance.$dirty">
                  <span v-show="!$v.form.distance.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.distance.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Vehicle Type -->
            <div class="meep-input">
              <md-field>
                <label for="scope">
                  {{ $t("simulateurs.frais.vehicleType") }}
                </label>

                <md-select
                  id="type"
                  v-model="$v.form.vehicleType.$model"
                  name="type"
                >
                  <md-option
                    v-for="(type, index) in vehicleTypes"
                    :key="index"
                    :value="type.value"
                  >
                    {{ type.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.frais.vehiclePower") }}</label>
                <md-select
                  id="type"
                  v-model="$v.form.vehiclePower.$model"
                  name="type"
                >
                  <md-option
                    v-for="(power, index) in vehiclePowers"
                    :key="index"
                    :value="power.value"
                  >
                    {{ power.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.frais.kmEstimation") }}</label>
                <md-input
                  v-model.number="$v.form.kmEstimation.$model"
                  name="kmEstimation"
                />
                <span class="md-suffix">{{ $t("suffix.kilometre") }}</span>

                <template v-if="$v.form.kmEstimation.$dirty">
                  <span
                    v-show="!$v.form.kmEstimation.numeric"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span
                    v-show="!$v.form.kmEstimation.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>
            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>

            <div class="meep-input meep-input-big-result">
              <p class="text">{{ $t("simulateurs.frais.result") }}</p>
              <p class="result">{{ $$filters.formatNumber(result) }}€</p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import { required, numeric } from "vuelidate/lib/validators";
import PageHeader from "@/components/PageHeader";
import { vehicleTypes, vehiclePowers } from "../../constants";
import calculatorsModel from "@/model/calculators";
import { isBetween } from "@/services/util";

export default {
  name: "Frais",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
  },

  validations: {
    form: {
      distance: { required, numeric },
      vehicleType: {},
      vehiclePower: { numeric },
      kmEstimation: { required, numeric },
    },
  },

  data() {
    return {
      form: {
        distance: null,
        vehicleType: null,
        vehiclePower: null,
        kmEstimation: null,
      },
      vehicleTypes,
      vehiclePowers,
      result: 0,
      fraisData: [],
    };
  },

  async mounted() {
    this.fraisData = await calculatorsModel.getAllKilo();
  },

  methods: {
    calcu({ distance, vehicleType, vehiclePower, kmEstimation }) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (!vehiclePower) {
          vehiclePower = 1;
        }
        if (!vehicleType) {
          vehicleType = "auto";
        }

        const result = this.fraisData.find(item => {
          const cvExp =
            typeof item.cv === "number"
              ? item.cv === vehiclePower
              : isBetween(vehiclePower, item.cv_min, item.cv_max);

          const kmExp = isBetween(kmEstimation, item.km_min, item.km_max);

          return item.type_vehicule === vehicleType && kmExp && cvExp;
        });

        const amount =
          result.montant_additionnel > 0
            ? (result.montant_additionnel / kmEstimation) * distance
            : result.montant_additionnel;

        this.result = result.coef * distance + amount;
      }
    },
  },
};
</script>
